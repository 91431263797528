@font-face {
  font-family: "outerorbit";
  src: url("~@/scss/data/outerorbit.woff2") format("woff2"),
    url("~@/scss/data/outerorbit.woff") format("woff"),
    url("~@/scss/data/outerorbit.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Thin.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Regular.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Medium.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Light.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Bold.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Karasuma Gothic";
  src: url("~@/scss/data/KarasumaGothic-Black.woff2") format("woff2"),
    url("~@/scss/data/KarasumaGothic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.app-container {
  background-color: #01002f !important;
}

.route-wrapper {
  background-image: url("~@/assets/background/PlatformBackground.webp");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.theme--dark.v-list-item--active::before {
  opacity: 0 !important;
}
.group-tile .v-list-group__items {
  max-height: 20vh;
  overflow-y: auto;
}

@media screen and (max-width: 950px) {
  .theme--dark.v-list-item:hover::before {
    opacity: 0 !important;
  }
}

.mobile-menu-tile .v-list-item {
  padding: 0 !important;
}

.label-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: #f4f5f6;
}

.login-input .v-input__control .v-input__slot {
  border-radius: 12px;
}
.login-input .v-input__control .v-input__slot:hover,
.login-input .v-input__control .v-input__slot:focus,
.login-input .v-input__control .v-input__slot:focus-visible,
.login-input .v-input__control .v-input__slot:focus-within {
  border: none;
  border-radius: 12px;
}
$font-family: "Karasuma Gothic";

.login-card {
  background: linear-gradient(
      109.46deg,
      rgba(1, 0, 46, 0.2) 1.57%,
      rgba(1, 0, 46, 0.2) 100%
    ),
    linear-gradient(
      109.46deg,
      rgba(201, 201, 201, 0.4) 1.57%,
      rgba(196, 196, 196, 0.1) 100%
    );
  border-radius: 12px;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #01002e !important;
}

.chat-text-area .v-input__control .v-text-field__details {
  display: none;
}
